<template>
  <div>
    <Videos :contents="videos" />
  </div>
</template>
<style>
.slick-bg:before {
  display: none;
 }
</style>
<script>
import { core } from '../../config/pluginInit'
import Videos from './MovieCategoryPage/videos'
import ApiService from '../../services/api'

export default {
  name: 'MovieCategory',
  data () {
    return {
      videos: []
    }
  },
  components: {
    Videos
  },
  methods: {
    getContentBytCategory () {
      ApiService.getContentByCategory(this.$route.params.category).then((response) => {
        this.$store.commit('setPoints', response.data.user_points)
        this.videos = response.data.data
      })
    }
  },
  mounted () {
    core.index()
    this.getContentBytCategory()
  }
}
</script>
